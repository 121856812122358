var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        visible: _vm.value,
        title: _vm.$t("lbl_write_off_detail"),
        width: "920px"
      }
    },
    [
      !_vm.disallowEdit
        ? _c(
            "div",
            { staticClass: "mb-3" },
            [
              _c(
                "a-button",
                {
                  staticClass: "mr-3",
                  attrs: { type: "danger", icon: "delete" },
                  on: { click: _vm.deleteRow }
                },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_delete")) + " ")]
              ),
              _c(
                "a-button",
                {
                  attrs: { type: "primary", icon: "plus" },
                  on: { click: _vm.addRow }
                },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_add_row")) + " ")]
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "a-table",
        {
          attrs: {
            "data-source": _vm.dtSource,
            pagination: {
              showTotal: function(total) {
                return _vm.$t("lbl_total_items", { total: total })
              }
            },
            scroll: { y: 320 },
            "row-key": function(r, i) {
              return i
            },
            "row-selection": {
              selectedRowKeys: _vm.selectedRowKeys,
              onChange: _vm.onRowSelect
            }
          }
        },
        [
          _c(
            "a-table-column",
            { key: "no", attrs: { "data-index": "no", width: "75px" } },
            [
              _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                _vm._v(_vm._s(_vm.$t("lbl_number_short")))
              ])
            ]
          ),
          _c(
            "a-table-column",
            {
              key: "amount",
              attrs: { "data-index": "amount" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(text, record) {
                    return _c("a-input-number", {
                      staticStyle: { width: "100%" },
                      attrs: {
                        value: record.amount,
                        formatter: _vm.formatterNumber,
                        parser: _vm.reverseFormatNumber,
                        precision: _vm.DECIMAL_PLACES_CURRENCY,
                        readonly: _vm.disallowEdit
                      },
                      on: {
                        change: function(e) {
                          return _vm.onChangeAmount(e, record.no - 1)
                        }
                      }
                    })
                  }
                }
              ])
            },
            [
              _c(
                "span",
                {
                  staticClass: "is-required",
                  attrs: { slot: "title" },
                  slot: "title"
                },
                [_vm._v(_vm._s(_vm.$t("lbl_amount")))]
              )
            ]
          ),
          _c(
            "a-table-column",
            {
              key: "description",
              attrs: { "data-index": "description" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(text, record) {
                    return _c("a-textarea", {
                      attrs: {
                        placeholder: _vm.$t("lbl_type_here"),
                        rows: { minRows: 1, maxRows: 4 },
                        "read-only": _vm.disallowEdit,
                        "allow-clear": ""
                      },
                      model: {
                        value: record.description,
                        callback: function($$v) {
                          _vm.$set(record, "description", $$v)
                        },
                        expression: "record.description"
                      }
                    })
                  }
                }
              ])
            },
            [
              _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                _vm._v(_vm._s(_vm.$t("lbl_description")))
              ])
            ]
          ),
          _c(
            "a-table-column",
            {
              key: "accountId",
              attrs: { "data-index": "accountId" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(text, record) {
                    return _c("CSelectAccountingAccount", {
                      attrs: {
                        value: record.accountId,
                        disabled: _vm.disallowEdit,
                        "for-list": false,
                        "prop-account-name": record.accountName,
                        "label-key": ["code", "description"]
                      },
                      on: {
                        "on-select": function(ref) {
                          var value = ref.value

                          return _vm.onChangeAccount(value, record.no - 1)
                        }
                      }
                    })
                  }
                }
              ])
            },
            [
              _c(
                "span",
                {
                  staticClass: "is-required",
                  attrs: { slot: "title" },
                  slot: "title"
                },
                [_vm._v(_vm._s(_vm.$t("lbl_account")))]
              )
            ]
          ),
          _c("template", { slot: "footer" }, [
            _c("p", [
              _vm._v(
                _vm._s(_vm.$t("lbl_total_amount")) +
                  ": " +
                  _vm._s(_vm._f("currency")(_vm.getTotalAmount()))
              )
            ])
          ])
        ],
        2
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c("a-button", { on: { click: _vm.handleClose } }, [
            _vm._v(" " + _vm._s(_vm.$t("lbl_close")) + " ")
          ]),
          _c(
            "a-button",
            {
              attrs: {
                type: "primary",
                icon: "save",
                disabled: _vm.disabledSave()
              },
              on: { click: _vm.handleSave }
            },
            [_vm._v(" " + _vm._s(_vm.$t("lbl_save")) + " ")]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }