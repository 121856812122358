























































































































import Vue from "vue";
import { formatterNumber, reverseFormatNumber } from "@/validator/globalvalidator";
import { DECIMAL_PLACES_CURRENCY } from "@constant/global.constant";
import { IReceiptLine, WriteOffDTO } from "@/models/interface-v2/account-receivables.interface";
import { Decimal } from "decimal.js-light";
import { DISALLOW_EDIT } from "../ReceiptArForm.vue";

export default Vue.extend({
  name: "ModalWriteOff",
  components: {
    CSelectAccountingAccount: () => import("@/components/shared/select-accounting-account/CSelectAccountingAccount.vue"),
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    propDataWriteoff: {
      type: Object as () => IReceiptLine,
      default: undefined,
    },
    propStatus: {
      type: String,
      default: undefined,
    }
  },
  data() {
    return {
      DECIMAL_PLACES_CURRENCY,
      dtSource: [] as WriteOffDTO[],
      selectedRowKeys: [] as number[],
      deletedRow: [] as string[],
    };
  },
  computed: {
    totalAmount(): number {
      return this.dtSource.reduce((a, b) => new Decimal(b.amount ?? 0).plus(a).toNumber(), 0);
    },
    disallowEdit(): boolean {
      return DISALLOW_EDIT.includes(this.propStatus);
    },
  },
  watch: {
    propDataWriteoff: {
      immediate: true,
      handler: "prefill",
    }
  },
  methods: {
    reverseFormatNumber,
    formatterNumber,
    prefill(): void {
      if (!this.propDataWriteoff) return;
      const source = this.propDataWriteoff.writeOffRequestDTOS ?? this.propDataWriteoff.writeOffList ?? [];
      this.dtSource = source.map((x, i) => ({ ...x, no: i + 1 }));
      this.setColNumber();
    },
    handleClose(): void {
      this.$emit("input", false);
    },
    handleSave(): void {
      this.$emit("on-save", { value: this.dtSource, deletedRow: this.deletedRow, });
      this.handleClose();
    },
    deleteRow(): void {
      const dtSource = [...this.dtSource];
      this.dtSource = dtSource.filter((_x, i) => !this.selectedRowKeys.includes(i));

      // assign deleted row
      this.deletedRow = dtSource.filter((_x, i) => this.selectedRowKeys.includes(i)).map(x => x.id ?? "");

      this.selectedRowKeys = [];
      this.setColNumber();
    },
    addRow(): void {
      const newRow: WriteOffDTO = {
        no: this.dtSource.length,
        id: null,
        accountId: "",
        amount: 0,
        description: "",
      };
      this.dtSource.unshift(newRow);
      this.setColNumber();
    },
    setColNumber(): void {
      this.dtSource.forEach((x, i) => x.no = i + 1);
    },
    onRowSelect(index: number[]): void {
      this.selectedRowKeys = index;
    },
    disabledSave(): boolean {
      return !!this.dtSource.find(x => !x.amount || !x.accountId);
    },
    onChangeAmount(e, index: number): void {
      this.dtSource[index].amount = e === "-" ? 0 : e;
    },
    onChangeAccount(e: string, index: number): void {
      this.dtSource[index].accountId = e;
    },
    getTotalAmount(): number {
      return this.dtSource.reduce((a, b) => new Decimal(b.amount || 0).plus(a).toNumber(), 0);
    },
  }
});
